<template>
  <div ref="el" class="bg-white rounded-minilu-xl">
    <ClientOnly>
      <div v-if="!session.isLoggedIn">
        <div
          class="px-sm sm:px-md md:px-[120px] pt-[53px] pb-xl"
          :class="{
            '!pt-0': dialogStore.headline && isInDialog,
          }"
        >
          <Form
            id="login-form"
            :headline="headlineTranslation"
            submit-label="login.login"
            :is-loading="isLoading"
            test-id="logInButton"
            @submit="onSubmit"
          >
            <template #errors>
              <slot v-if="!errors.length" name="hint" />
              <div
                v-if="
                  errors.length &&
                  errors.includes(ErrorIdent.INVALID_CREDENTIALS)
                "
              >
                <InvalidCredentialsError />
              </div>
              <div
                v-if="
                  errors.length && errors.includes(ErrorIdent.UNKNOWN_ERROR)
                "
              >
                <UnknownError />
              </div>
            </template>
            <template #default>
              <TextInput
                name="email"
                :model-value="email"
                label="login.emailLabel"
                placeholder="login.emailPlaceholder"
                required-message="login.emailRequired"
                :autofocus="true"
                testid="inputUserName"
              />

              <Password
                name="password"
                label="login.passwordLabel"
                placeholder="login.passwordLabel"
                testid="inputPassword"
                @click-forgot="() => (view = Views.FORGOT)"
              />
            </template>
          </Form>
          <div
            v-if="showRegisterLink"
            class="flex items-center justify-center w-full mt-md"
          >
            <div
              class="flex gap-2 cursor-pointer place-items-center text-minilu-base font-pero text-18"
              @click="$emit('clickRegister')"
            >
              <span class="anim__underline-link minilu-anim-link">
                {{ t('login.register') }}
              </span>
              <FaIcon icon-class="fas fa-chevron-right" />
            </div>
          </div>
          <GoogleRecaptchaNote v-if="usesCaptcha" class="mt-sm md:mt-md" />
        </div>
      </div>

      <div
        v-else-if="!hideLoggedIn"
        class="px-sm sm:px-md md:px-[120px] pt-[53px] pb-xl"
      >
        <div class="flex items-center justify-center">
          {{ t('login.alreadyLoggedIn') }}
        </div>
        <ButtonBasic
          classes="w-full px-sm md:px-0 pt-sm"
          :label="t('login.logout')"
          :type="EButtonType.SUBMIT"
          :btn-style="EButtonStyle.CTA"
          :loading-animation="isLoading"
          bg-color="var(--minilu-btn-base)"
          :disabled="isLoading"
          full-width
          @click="logout"
        ></ButtonBasic>
      </div>
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import ButtonBasic from '~~/src/components/components/core/basic-button/minilu/basic-button.vue';
import { TextInput } from '@/complib';
import Password from '~/components/formFields/password/minilu/password.vue';
import Form from '~/components/formFields/form/form.vue';
import InvalidCredentialsError from '../../components/invalidCredentialsError/minilu/invalidCredentialsError.vue';
import { useLoginForm, Views } from '../../useLoginForm';
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import { ErrorIdent } from '~/@types/errorIdents';
import FaIcon from '@/components/fa-icon.vue';
import { useDialogStore } from '@/stores/useDialogStore';
import { useSessionStore } from '~/stores/useSessionStore';
import GoogleRecaptchaNote from '@/components/formTools/recaptcha/recaptchaNote.vue';

const session = useSessionStore();
const { t } = useTrans();

defineProps({
  headlineTranslation: {
    type: String,
    required: false,
    default: 'login.headline',
  },
  showRegisterLink: {
    type: Boolean,
    required: false,
    default: true,
  },
  hideLoggedIn: {
    type: Boolean,
    required: false,
    default: false,
  },
  email: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits<{
  (
    e: 'updateView',
    view: Views,
    data?: { legacyHash: string; email?: string },
  ): void;
  (e: 'clickRegister'): void;
}>();

const { onSubmit, errors, isLoading, logout, view, legacyHash, usesCaptcha } =
  useLoginForm({ noCaptcha: session.isLoggedIn });

const dialogStore = useDialogStore();
const el = ref<HTMLElement | null>(null);

const isInDialog = useIsInDialog(el);

watch(view, (nv) => {
  if (nv === Views.LEGACY) {
    const email =
      (document.getElementsByName('email')[0] as HTMLInputElement)?.value ?? '';
    emit('updateView', nv, { legacyHash: legacyHash.value, email });
  } else {
    emit('updateView', nv);
  }
});
</script>
