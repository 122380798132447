<template>
  <ErrorFrame>
    <div class="flex flex-col gap-sm">
      <div>
        {{ t('login.invalidError') }}
      </div>
    </div>
  </ErrorFrame>
</template>
<script setup lang="ts">
import ErrorFrame from '~~/src/components/formFields/components/errorFrame/errorFrame.vue';

const { t } = useTrans();
</script>
